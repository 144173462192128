<style lang="scss">
    @import "~@/assets/css/var";
    .ts-upload__item {
        position: relative;
        padding: 15px 0;

        .close {
            position: absolute;
            top: 0;
            right: 0;
            padding: 10px;
            cursor: pointer;
            display: none;

            i {
                font-size: 14px;
                color: $red;
            }

            &:hover {
                opacity: .7;
            }
        }
        &:hover {
            background: rgba(0, 0, 0, 0.1);
            .close {
                display: block;
            }
        }
    }
</style>
<template>
    <div class="ts-upload">
        <div class="ts-upload__item" v-for="(item, index) in file"  :key="'general-upload-' + index">
            <img :src="item.url" alt="">

            <div class="close" @click="delFile(index)"><i class="el-icon-close"></i></div>
        </div>
        <el-upload ref="upload" :action="action" :auto-upload="false" :multiple="true" :show-file-list="false"
                   :data="postData" :on-change="handleChange" :on-success="success" :on-error="failed"
                   :before-upload="beforeUpload">
            <el-button class="btn-blue" :loading="fileLoading">
                添加图片
            </el-button>
        </el-upload>
    </div>
</template>
<script>
    import qiniu from '@/util/qiniu'
    export default {
        data() {
            return {
                fileLoading: false,
                action: qiniu.upload,
                postData: {
                    token: '',
                    key: '',
                },
                file: [],
            }
        },
        methods: {
            handleChange() {
                if(this.read || this.fileLoading) {
                    return
                }
                this.fileLoading = true
                qiniu.getToken().then(res => {
                    if(res.success){
                        this.postData.token = res.data
                        this.$refs.upload.submit();
                    }
                })
            },
            beforeUpload(file) {
                const name = file.name
                const splitStart = name.lastIndexOf('.')

                this.postData.key = `${name.substr(0, splitStart)}-${new Date().Format('yyyyMMddhhmmss')}${name.substr(splitStart)}`
            },
            success(obj) {
                const item = {
                    url: obj.data.url
                }

                this.getImgBase64(obj.data.url, data => {
                    this.$set(item, 'base64', data)
                })

                this.file.push(item)
                setTimeout(() => {
                    this.fileLoading = false
                }, 300)
            },
            failed(obj) {
                console.log(obj)
                setTimeout(() => {
                    this.fileLoading = false
                }, 300)
            },
            image2Base64(img) {
                const canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, img.width, img.height)
                return canvas.toDataURL("image/png")
            },
            getImgBase64(url, cb){
                const img = new Image();
                img.crossOrigin=""
                img.src = url;
                img.onload = () => {
                    cb(this.image2Base64(img))
                }
            },
            delFile(index) {
                this.file.splice(index, 1)
            },
        }
    }
</script>